import { computed, observable } from 'mobx'

export default class NotificationModel {
  @observable _id
  @observable title
  @observable body

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable userId
  @observable albumId
  @observable appIdentifierId
  @observable trackId

  @observable rewardId
  @observable type
  @observable isRead

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.title = props.title
      this.body = props.body

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.userId = props.userId
      this.albumId = props.albumId
      this.appIdentifierId = props.appIdentifierId
      this.trackId = props.trackId

      this.rewardId = props.rewardId
      this.type = props.type
      this.isRead = props.isRead

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path
    }
  }
}
