import React, { useEffect, useState } from 'react'
import { RootTemplate, ActivityLogsTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { NotificationsTemplate } from '@components/templates/NotificationsTemplate'

interface Props {
  artistStore: ArtistStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const NotificationsPage = ({
  statsStore,
  authStore,
  artistStore,
  location,
  logout,
  user,
}: Props) => {
  const [offset, setOffset] = useState(0)

  const artist = artistStore.artistDetail
  const fetchNotificationList = authStore.fetchNotificationList || (() => {})

  const notificationList = authStore.notificationList || []
  const notificationOffset = authStore.notificationOffset || []

  const artistModifyRecordList = artistStore.artistModifyRecordList || null
  const artistModifyRecordListCount =
    artistStore.artistModifyRecordListCount || null

  const fetchNotifications = async () => {
    const result = await fetchNotificationList({
      __offset: 0,
      __limit: 20,
      type:
        authStore?.currentUser?.type === 'artist'
        || authStore?.currentUser?.type === 'company'
          ? 'NEW_ALBUM,MELON_LISTENER_NEW_TRACK,MELON_LISTENER_INCREASE_TRACK,MELON_TOP_100_TRACK'
          : 'NEW_ALBUM,MELON_TOP_100_TRACK',
    })

    if (!result) return
  }

  useEffect(() => {
    fetchNotifications()
  }, [user, artistStore, fetchNotificationList, location])

  // useEffect(() => {
  //   if (offset > 0) {
  //     fetchNotificationList(artistStore.artistDetail._id, offset)
  //   }
  // }, [offset, artistStore, fetchNotificationList])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        <NotificationsTemplate
          fetchNotificationList={fetchNotificationList}
          notificationList={notificationList}
          offset={notificationOffset}
          currentUser={authStore?.currentUser}
        />
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'statsStore',
)(observer(NotificationsPage))
