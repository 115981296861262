import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  Container,
  H6,
  Caption1,
  Caption2,
  H5,
} from '@components/atoms'

import moment from 'moment'

import { colors } from '@colors/'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 16px 0 10px 0;
`

const ListBox = styled(Flex)`
  flex-direction: column;
  div + div {
    margin-top: 8px;
  }
`

export const NotificationsTemplate = ({
  fetchNotificationList,
  notificationList,
  offset,
  currentUser,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isFinish, setIsFinish] = useState(false)

  const fetchMoreNotification = async () => {
    const result = await fetchNotificationList({
      __offset: offset + 1,
      __limit: 20,
      type:
        currentUser?.type === 'artist' || currentUser?.type === 'company'
          ? 'NEW_ALBUM,MELON_LISTENER_NEW_TRACK,MELON_LISTENER_INCREASE_TRACK,MELON_TOP_100_TRACK'
          : 'NEW_ALBUM,MELON_TOP_100_TRACK',
    })

    setIsFetching(false)

    if (!result) {
      setIsFinish(true)
    }
  }

  useEffect(() => {
    if (isFetching && !isFinish) {
      fetchMoreNotification()
    }
  }, [isFetching])

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          알림
        </Text>
      </Header>
      <Content>
        <Flex type="column">
          <ListBox>
            {
              notificationList
              && notificationList.length > 0
              && notificationList.map((item, index) => {
                return (
                  <NotificationItem key={item._id} item={item} index={index} />
                )
              })
            }
          </ListBox>
          {
            notificationList && !isFinish && (
              <Flex
                style={
                  {
                    alignSelf: 'flex-start',
                    width: '100%',
                    height: 32,
                    backgroundColor: '#d4d4d4',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                  }
                }
                onClick={() => setIsFetching(true)}
              >
                <Caption1 type="Bold" color="#646464">
                더보기
                </Caption1>
              </Flex>
            )
          }
        </Flex>
      </Content>
    </Container>
  )
}

const NotificationItem = ({ item, index }) => {
  const dateFromString = date => {
    const diffDay = moment().diff(moment(date), 'days')
    const diffTime = moment().diff(moment(date), 'minute')

    if (diffDay > 7) {
      if (moment(date).isSame(moment(), 'years')) {
        return moment(date).format('M월 D일 hh:mm')
      }

      return moment(date).format('YYYY-MM-DD')
    }
    if (diffDay === 0) {
      if (diffTime < 60) {
        return `${diffTime}분 전`
      }
      return `${Math.floor(diffTime / 60)}시간 전`
    }
    return `${diffDay}일 전`
  }

  return (
    <Flex
      style={
        {
          padding: '10px 24px',
        // borderBottom: `0.5px solid ${colors.light_pink}`,
        }
      }
    >
      {
        !!item?.image64Path && (
          <div
            style={
              {
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                background: colors.black60,
                marginRight: '12px',
              }
            }
          />
        )
      }
      <Flex
        style={
          {
            gap: '4px',
            flex: 1,
            userSelect: 'none',
          }
        }
        justify={'center'}
        type={'column'}
      >
        <H6 align={'left'}>{item.body}</H6>
        <Flex align="center">
          <Caption1 color={colors.brown_grey} align={'left'}>
            {dateFromString(item?.createdAt)}
          </Caption1>
          {
            moment().diff(moment(item.createdAt), 'days') <= 3 && !item.isRead && (
              <Caption2
                color={'red'}
                style={
                  {
                    marginLeft: '4px',
                    paddingBottom: '2px',
                  }
                }
              >
              *new
              </Caption2>
            )
          }
        </Flex>
      </Flex>
    </Flex>
  )
}
