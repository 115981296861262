import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { UserModel } from './models'
import NotificationModel from './models/NotificationModel'

export default class AuthStore {
  @observable currentUser
  @observable jsonWebToken
  @observable isAdminPassword
  @observable isTempPassword

  @observable notificationList = [] // 알림 리스트
  @observable notificationOffset = 0

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.currentUser = null
    this.jsonWebToken = null
    this.isAdminPassword = false
    this.isTempPassword = false

    this.notificationList = [] // 알림 리스트
    this.notificationOffset = 0
  }

  @action.bound
  async initClient() {
    if (window['sessionStorage'].getItem('jwt_token')) {
      this.jsonWebToken = window['sessionStorage'].getItem('jwt_token')
    }
    else {
      this.jsonWebToken = window['localStorage'].getItem('myplam_jwt_token')
    }
  }

  @action.bound
  async login({ account, password, isAutoLogin = false } = {}) {
    if (this.currentUser && this.currentUser._id && !isAutoLogin) return
    return this.store.useLoading(async () => {
      let res = false

      if (account && password) {
        res = await this.network.authNetwork.postLogin({ account, password })
        if (!res) {
          this.logout()
          return
        }
        this.isTempPassword = res?.data?.isTempPassword
        this.currentUser = new UserModel(this.store, res.data['user'])

        this.jsonWebToken = res.data['jsonWebToken']
        this.isAdminPassword = res.data['isAdminPassword'] || false
        this.jsonWebToken
          && window['sessionStorage'].setItem('jwt_token', this.jsonWebToken)

        if (isAutoLogin) {
          window['localStorage'].setItem('myplam_jwt_token', this.jsonWebToken)
        }

        return res
      }
      if (this.jsonWebToken) {
        // 토큰 로그인
        res = await this.network.authNetwork.postTokenLogin({
          jsonWebToken: this.jsonWebToken,
        })
        if (!res) {
          this.logout()
          return
        }

        this.currentUser = new UserModel(this.store, res.data['user'])

        if (res && res.data['user']['type'] === 'company') {
          // 회사로 로그인 시, 관리 아티스트 패치
          this.store.connectionsStore.fetchartistCompanyConnectionList(
            res.data['user']['_id'],
          )

          // 회사로 로그인 시, 활성화 된 아티스트 fetch
          const currentArtistId = this.getCurrentArtistId()
          if (currentArtistId) {
            const resFetchArtistDetail = await this.store.artistStore.fetchArtistDetail(
              currentArtistId,
            )
            if (!resFetchArtistDetail) return

            this.currentUser = new UserModel(this.store, {
              ...res.data['user'],
              artistId: currentArtistId,
              isArtistViewMode: true,
            })
          }
        }

        this.jsonWebToken = res.data['jsonWebToken']
        this.isAdminPassword = res.data['isAdminPassword'] || false
        this.jsonWebToken
          && window['sessionStorage'].setItem('jwt_token', this.jsonWebToken)

        return res
      }
    })
  }

  @action.bound
  async logout() {
    return this.store.useLoading(async () => {
      window['sessionStorage'].removeItem('jwt_token')
      this.clearCurrentArtistId()
      window['localStorage'].removeItem('myplam_jwt_token')
      this.init()
      this.store.artistStore.init()
      this.store.trendStore.init()
      this.store.revenueStore.init()
      this.store.connectionsStore.init()
      this.store.settlementStore.init()
      this.store.adjustmentContractStore.init()
    })
  }

  @action.bound
  async register(newUser) {
    return this.store.useLoading(async () => {
      if (!newUser) return

      const res = await this.network.authNetwork.postUser(newUser)
      if (!res) return

      return res
    })
  }

  @action.bound
  async updateUser(user) {
    return this.store.useLoading(async () => {
      if (!user) return

      const res = await this.network.authNetwork.putUser(user)

      if (!res) return
      if (res?.data?.user) this.currentUser = res.data.user

      return res
    })
  }

  @action.bound
  async emailAuth(userId) {
    return this.store.useLoading(async () => {
      if (!userId) return

      const res = await this.network.authNetwork.postEmailAuth({ _id: userId })
      if (!res) return

      return res
    })
  }

  @action.bound
  async updateEmail(params) {
    return this.store.useLoading(async () => {
      if (!params) return

      const res = await this.network.authNetwork.putEmail(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  async findAccount(email) {
    return this.store.useLoading(async () => {
      if (!email) return

      const res = await this.network.authNetwork.postAccount({ email })
      if (!res) return

      return res
    })
  }

  @action.bound
  async findPassword(params) {
    return this.store.useLoading(async () => {
      if (!params) return

      const res = await this.network.authNetwork.postPassword(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  async changePassword(params) {
    return this.store.useLoading(async () => {
      if (!params) return

      const res = await this.network.authNetwork.putPassword(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  artistViewMode = async artistId => {
    const res = await this.store.artistStore.fetchArtistDetail(artistId)
    if (!res) return

    this.currentUser['artistId'] = this.store.artistStore.artistDetail._id
    this.currentUser['isArtistViewMode'] = true
    this.setCurrentArtistId(this.currentUser['artistId'])
  }

  setCurrentArtistId = async artistId => {
    if (artistId) {
      window['sessionStorage'].setItem('currentArtistId', artistId)
    }
  }
  clearCurrentArtistId = () => {
    window['sessionStorage'].removeItem('currentArtistId')
    this.currentUser['artistId'] = null
    this.currentUser['isArtistViewMode'] = false
  }
  getCurrentArtistId = () => window['sessionStorage'].getItem('currentArtistId')

  @action.bound
  fetchNotificationList = async (params: {
    __offset?: Number,
    __limit?: Number,
    __sortBy?: string,
    __sort?: Number,
    type?: string,
  }) => {
    // return this.store.useLoading(async () => {
    const res = await this.network.authNetwork.getNotiList(params)

    if (!res?.alertNotificationList || res?.alertNotificationList?.length === 0)
      return false

    if (params.__offset === 0) {
      this.notificationList = res.alertNotificationList
        .filter(elem => !!elem)
        .map(elem => new NotificationModel(this.store, elem))

      this.notificationOffset = 0
    }
    else if (params.__offset > 0) {
      this.notificationList = this.notificationList.concat(
        (this.notificationList = res.alertNotificationList
          .filter(elem => !!elem)
          .map(elem => new NotificationModel(this.store, elem))),
      )

      this.notificationOffset = params.__offset
    }

    return res.alertNotificationList
    // })
  }
}
