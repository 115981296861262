import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { inject, observer } from 'mobx-react'
import LoginPage from './LoginPage'
import ArtistPage from './ArtistPage'
import CompanyPage from './CompanyPage'
import AlbumPage from './AlbumPage'
import AlbumDetailPage from './AlbumDetailPage'
import RegisterPage from './RegisterPage'
import RegisterCompletePage from './RegisterCompletePage'
import RegisterUpdateEmailPage from './RegisterUpdateEmailPage'
import FindAccountPage from './FindAccountPage'
import FindPasswordPage from './FindPasswordPage'
import AuthEmailPage from './AuthEmailPage'
import InsightPage from './InsightPage'
import NotFoundPage from './NotFoundPage'
import InsightArtistPage from './InsightArtistPage'
import InsightTrackPage from './InsightTrackPage'
import InsightTrackYoutubePage from './InsightTrackYoutubePage'
import InsightArtistSnsPage from './InsightArtistSnsPage'
import ActivityLogsPage from './ActivityLogsPage'
import ProtectedRoute from './ProtectedRoute'
import InsightRevenuePage from './InsightRevenuePage'
import InsightRevenueTrackPage from './InsightRevenueTrackPage'
import InsightRevenueAlbumPage from './InsightRevenueAlbumPage'
import ChangePasswordPage from './ChangePasswordPage'
import CompanyInsightSettlementPage from './CompanyInsightSettlementPage'
import InsightSettlementPage from './InsightSettlementPage'
import InsightSettlementMonthPage from './InsightSettlementMonthPage'
import InsightSettlementTrackPage from './InsightSettlementTrackPage'
import InsightSettlementAlbumPage from './InsightSettlementAlbumPage'
import InsightSmartLinkPage from './InsightSmartLinkPage'

// statement
import CompanyRoyaltyStatementPage from './royaltyStatements/CompanyRoyaltyStatementPage'
import CompanyArtistContractPage from './royaltyStatements/CompanyArtistContractPage'
import CompanyRoyaltyStatementListPage from './royaltyStatements/CompanyRoyaltyStatementListPage'
import CompanyClosedRoyaltyStatementPage from './royaltyStatements/CompanyClosedRoyaltyStatementPage'
import NotificationsPage from './NotificationsPage'

const Routes = ({ authStore }) => {
  useEffect(() => {
    if (
      authStore?.currentUser?.type === 'artist'
      && !authStore?.currentUser?.artistId
    ) {
      window.location.href = '/artist'
    }
  }, [authStore])

  useEffect(() => {
    if (authStore.isTempPassword) window.location.href = '/change/password'
  }, [authStore.isTempPassword])

  return (
    <Router>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={
            authStore.currentUser && authStore.currentUser.type === 'company'
              ? CompanyPage
              : ArtistPage
          }
        />
        <ProtectedRoute exact path="/artist" component={ArtistPage} />
        <Route exact path="/login" component={LoginPage} />
        <ProtectedRoute exact path="/album" component={AlbumPage} />
        <ProtectedRoute
          exact
          path="/activity-logs"
          component={ActivityLogsPage}
        />
        <ProtectedRoute
          exact
          path="/insight/smart-link"
          component={InsightSmartLinkPage}
        />
        <ProtectedRoute
          exact
          path="/insight/marketing"
          component={InsightPage}
        />
        <ProtectedRoute
          exact
          path="/insight/artist"
          component={InsightArtistPage}
        />
        <ProtectedRoute
          exact
          path="/insight/artist/track/:id"
          component={InsightTrackPage}
        />
        <ProtectedRoute
          exact
          path="/insight/artist/track/youtube/:id"
          component={InsightTrackYoutubePage}
        />
        <ProtectedRoute
          exact
          path="/insight/company/:companyId/track/youtube/:id"
          component={InsightTrackYoutubePage}
        />
        <ProtectedRoute
          exact
          path="/insight/artist/sns/:snsType"
          component={InsightArtistSnsPage}
        />
        <ProtectedRoute
          exact
          path="/album/:albumId"
          component={AlbumDetailPage}
        />
        <Route exact path="/register" component={RegisterPage} />
        <ProtectedRoute
          exact
          path="/register/complete"
          component={RegisterCompletePage}
        />
        <ProtectedRoute
          exact
          path="/register/update-email"
          component={RegisterUpdateEmailPage}
        />
        <ProtectedRoute
          exact
          path="/insight/revenue"
          component={InsightRevenuePage}
        />
        <ProtectedRoute
          exact
          path="/insight/revenue/album/:id"
          component={InsightRevenueAlbumPage}
        />
        <ProtectedRoute
          exact
          path="/insight/revenue/company/album/:id"
          component={InsightRevenueAlbumPage}
        />
        <ProtectedRoute
          exact
          path="/insight/revenue/track/:id"
          component={InsightRevenueTrackPage}
        />
        <ProtectedRoute
          exact
          path="/insight/revenue/company/track/:id"
          component={InsightRevenueTrackPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement"
          component={InsightSettlementPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement/month"
          component={InsightSettlementMonthPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement/track/:id"
          component={InsightSettlementTrackPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement/album/:id"
          component={InsightSettlementAlbumPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement/company/:companyId/track/:id"
          component={InsightSettlementTrackPage}
        />
        <ProtectedRoute
          exact
          path="/insight/settlement/company/:companyId/album/:id"
          component={InsightSettlementAlbumPage}
        />
        <ProtectedRoute
          exact
          path="/company/insight/settlement"
          component={CompanyInsightSettlementPage}
        />
        <Route exact path="/find/account" component={FindAccountPage} />
        <Route exact path="/find/password" component={FindPasswordPage} />
        <ProtectedRoute
          exact
          path="/change/password"
          component={ChangePasswordPage}
        />
        <Route
          exact
          path="/register/auth-email/:type"
          component={AuthEmailPage}
        />
        <Route
          exact
          path="/register/auth-email/:type/:id"
          component={AuthEmailPage}
        />

        <ProtectedRoute
          exact
          path="/statement"
          component={CompanyRoyaltyStatementPage}
        />
        <ProtectedRoute
          exact
          path="/contract"
          component={CompanyArtistContractPage}
        />
        <ProtectedRoute
          exact
          path="/statement-list"
          component={CompanyRoyaltyStatementListPage}
        />
        <ProtectedRoute
          exact
          path="/statement-list/closed/"
          component={CompanyClosedRoyaltyStatementPage}
        />
        <ProtectedRoute
          exact
          path="/notification"
          component={NotificationsPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default inject('authStore')(observer(Routes))
