/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { Flex } from '@components/atoms'
import { CompanySidebar, Header, Sidebar } from '@components/organisms'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

const Container = styled(Flex)`
  flex-direction: column;
`
const Content = styled(Flex)``

const RootTemplate = ({ children, user, artist, logout, authStore }) => {
  const location = useLocation()

  const renderSidebar = () => {
    if (
      (location.pathname === '/' && user?.type === 'company')
      || noSideBarPageList.includes(location.pathname)
      || noSideBarPathRegexList.findIndex(
        (regex, index) => location.pathname.match(regex)?.length > 0,
      ) > -1
    ) {
      return <></>
    }
    else if (
      user
      && user.type === 'company'
      && (location.pathname === '/statement'
        || location.pathname === '/contract'
        || location.pathname === '/statement-list'
        || location.pathname === '/statement-list/closed')
    ) {
      return (
        <CompanySidebar artistId={artist && artist._id} authStore={authStore} />
      )
    }
    else {
      return <Sidebar artistId={artist && artist._id} userType={user?.type} />
    }
  }
  return (
    <Container>
      <Header user={user} artist={artist} logout={logout} />
      <Content>
        {// 홈에서 회사가 아닐때, 모든 페이지에 사이드바 생성
          renderSidebar()
        }
        {children}
      </Content>
    </Container>
  )
}

export default inject('authStore')(observer(RootTemplate))

const noSideBarPageList = ['/company/insight/settlement', '/notification']

const noSideBarPathRegexList = [
  /\/insight\/settlement\/company\/[^\/]+\/album\/[^\/]+[\/]?/g,
  /\/insight\/settlement\/company\/[^\/]+\/track\/[^\/]+[\/]?/g,
  /\/insight\/company\/[^\/]+\/track\/youtube\/[^\/]+[\/]?/g,
]
